import { Space } from 'antd';
import { camelCase, first, get, map, reduce, sortBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import PayrollFileConfigurationAPI from '../../../api/payroll-file-configurations';
import { CustomTable } from '../../../components';
import Text from '../../../components/Text';
import { getMinutesAsMilliseconds } from '../../../utils/numbers';

const PayrollExpandedRow = (rowData = {}) => {
  const { t, _id } = rowData;

  const formatResponse = useCallback(data => {
    return {
      ...data,
      fields: map(get(data, 'fields', []), fields => {
        return reduce(
          fields,
          (total, field) => {
            total[field.label] = field.value;
            return total;
          },
          {},
        );
      }),
      settings: data.settings
        ? sortBy(
            Object.keys(get(data, 'settings', {})).map(key => ({
              label: key,
              value: data.settings[key],
            })),
            ['label'],
          )
        : [],
    };
  }, []);

  const payrollFileConfigurationDetailsQuery = useQuery({
    enabled: !!_id,
    staleTime: getMinutesAsMilliseconds(30),
    select: formatResponse,
    queryKey: ['fetchPayrollFileConfigurationDetails', _id],
    queryFn: () => new PayrollFileConfigurationAPI().fetchPayrollFileConfigurationDetails(_id),
  });

  const fieldsColumns = useMemo(() => {
    const data = get(payrollFileConfigurationDetailsQuery.data, 'fields', []);

    if (data?.length) {
      const fields = Object.keys(first(data)).sort();

      return map(fields, fieldName => ({
        width: 150,
        key: camelCase(fieldName),
        title: fieldName,
        render: data => {
          return <div style={{ minWidth: 150 }}>{data[fieldName]}</div>;
        },
      }));
    }

    return [];
  }, [payrollFileConfigurationDetailsQuery.data]);

  return (
    <Space direction="vertical" size="large">
      <div>
        <Text variant="h5">{t('settings')}</Text>

        <CustomTable
          scroll={{ x: true, y: 300 }}
          showSearchInput={false}
          pagination={false}
          loading={payrollFileConfigurationDetailsQuery.isFetching}
          columns={[
            { width: 400, title: t('name'), dataIndex: 'label' },
            { title: t('value'), dataIndex: 'value' },
          ]}
          dataSource={get(payrollFileConfigurationDetailsQuery.data, 'settings', [])}
        />
      </div>

      {!!get(payrollFileConfigurationDetailsQuery.data, 'fields', [])?.length && (
        <div>
          <Text variant="h5">{t('fields')}</Text>

          <CustomTable
            scroll={{ x: true, y: 300 }}
            showSearchInput={false}
            pagination={false}
            loading={payrollFileConfigurationDetailsQuery.isFetching}
            columns={fieldsColumns}
            dataSource={get(payrollFileConfigurationDetailsQuery.data, 'fields', [])}
          />
        </div>
      )}
    </Space>
  );
};

export default PayrollExpandedRow;
