import { get } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { handleApiErrors } from '../../../api/axiosInstance';
import { RECEIPT_API } from '../../../api/receipt';
import ReceiptsTable from '../../../components/Table/ReceiptsTable';
import Toast from '../../../components/Toast';
import { STATUS_LIST } from '../../../enum';
import usePaginatedFiltersQuery from '../../../hooks/queries/usePaginatedFiltersQuery';
import useTableSort from '../../../hooks/useTableSort';
import { getMinutesAsMilliseconds } from '../../../utils/numbers';

const SelectApprovedReimbursementsSection = props => {
  const { t, companyId, paymentScheduleId, selectedRows, onSelect, disabled } = props;

  const { stringTableSort, handleTableSort } = useTableSort({ seqId: -1 });

  const receiptsQueryParams = useMemo(() => {
    return {
      paymentScheduleId,
      status: STATUS_LIST().Status.APPROVED,
    };
  }, [paymentScheduleId]);

  const {
    query: receiptsQuery,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedFiltersQuery(
    {
      staleTime: getMinutesAsMilliseconds(5),
      placeholderData: {
        data: [],
        overdueCount: 0,
        totalCount: 0,
      },
      enabled: Boolean(paymentScheduleId) && Boolean(companyId),
      queryKey: [
        'fetchCompanyTripReceipts',
        ...Object.values(receiptsQueryParams),
        stringTableSort,
      ],
      queryFn: () =>
        RECEIPT_API.fetchCompanyTripReceipts(
          { ...receiptsQueryParams, sort: stringTableSort },
          paginationConfig.current,
          paginationConfig.pageSize,
        ),
      onSuccess: response => {
        let receiptData = [];

        response.data.forEach(receipt => {
          receiptData.push({ ...receipt });
        });
      },
      onError: error => {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: t('fetchCompanyTripReceiptsError'),
          });
        });
      },
    },
    {
      resetPageQueryKey: Object.values(receiptsQueryParams),
    },
  );

  const handleTableChange = useCallback(
    ({ current }, _, sorters) => {
      handlePageChange(current);
      handleTableSort(sorters?.columnKey, sorters?.order);
    },
    [handlePageChange, handleTableSort],
  );

  const rowSelection = useMemo(
    () => ({
      type: 'checkbox',
      selectedRowKeys: selectedRows,
      preserveSelectedRowKeys: true,
      onChange: onSelect,
    }),
    [selectedRows, onSelect],
  );

  const tableProps = useMemo(
    () => ({
      t,
      asyncSort: true,
      showSearchInput: false,
      hiddenColumns: ['actions'],
      loading: receiptsQuery.isLoading || disabled,
      dataSource: get(receiptsQuery.data, 'data', []),
      onChange: handleTableChange,
      rowSelection,
      pagination: {
        pageSize: paginationConfig.pageSize,
        total: paginationConfig.total,
        current: paginationConfig.current,
        onShowSizeChange: handlePageSizeChange,
      },
    }),
    [
      t,
      receiptsQuery.isLoading,
      receiptsQuery.data,
      handleTableChange,
      rowSelection,
      disabled,
      paginationConfig,
      handlePageSizeChange,
    ],
  );

  return <ReceiptsTable {...tableProps} />;
};

export default SelectApprovedReimbursementsSection;
