import { Form, Space } from 'antd';
import { get, isEmpty, merge, omit } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-query';

import { handleApiErrors } from '../../../api/axiosInstance';
import PAYROLL_AUDITS_API from '../../../api/payroll-audits';
import FormItem from '../../../components/Form/FormItem';
import SectionStepWrapper from '../../../components/SectionStepWrapper';
import Select from '../../../components/Select';
import SubmitCancelButtonGroup from '../../../components/SubmitCancelButtonGroup';
import Toast from '../../../components/Toast';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import AddPayrollFileStepGroup from './FormStepActionGroups/AddPayrollFileStepGroup';

const PayrollFileModificationSection = ({ t }) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(1);
  const [formValues, setFormValues] = useState({});

  const handleFormValuesChange = useCallback((_, values) => {
    if ('paymentScheduleId' in values) {
      setFormValues(omit(values, ['payrollAuditId', 'tripReceipts', 'tripReceiptsIds']));
    } else {
      setFormValues(values);
    }
  }, []);

  const handleCancel = useCallback(() => {
    form.resetFields();
    setFormValues({});
  }, [form]);

  const modifyPayrollFileMutation = useMutation(PAYROLL_AUDITS_API.modifyPayrollAudits, {
    onError: error => handleApiErrors(error.response),
    onSuccess: () => {
      Toast({
        duration: 10, // seconds
        type: 'open',
        message: t('modifyPayrollAuditsSuccess'),
      });

      handleCancel();
    },
  });

  const handleSubmit = useCallback(() => {
    form.validateFields().then(() => {
      const { actionType, ...values } = formValues;

      let params = {
        tripReceiptsIds: values.tripReceiptsIds,
        paymentScheduleId: values.paymentScheduleId,
      };
      switch (actionType) {
        case 'add':
          merge(params, {
            payrollAuditId: values.payrollAuditId,
          });
          break;
        default:
          break;
      }

      modifyPayrollFileMutation.mutateAsync({
        action: actionType,
        ...params,
      });
    });
  }, [form, formValues, modifyPayrollFileMutation]);

  const setCurrentStepForActionType = useCallback(values => {
    if (isEmpty(get(values, 'actionType'))) {
      setCurrentStep(1);
    } else if (values.actionType === 'add') {
      if (isEmpty(get(values, 'paymentScheduleId'))) {
        setCurrentStep(2);
      } else if (isEmpty(get(values, 'payrollAuditId'))) {
        setCurrentStep(3);
      } else if (!get(values, 'tripReceiptsIds', [])?.length) {
        setCurrentStep(4);
      } else {
        setCurrentStep(5);
      }
    }
  }, []);

  useDidUpdateEffect(() => {
    setCurrentStepForActionType(formValues);
  }, [formValues]);

  return (
    <section>
      <Form form={form} onValuesChange={handleFormValuesChange}>
        <Space direction="vertical" size="middle" wrap={false}>
          <SectionStepWrapper
            step={1}
            title={t('selectActionType')}
            showContent={currentStep >= 1}
            isCompleted={currentStep > 1}
          >
            <FormItem label={t('actionType')} name="actionType">
              <Select
                fullWidth
                disabled={modifyPayrollFileMutation.isLoading}
                options={[
                  {
                    label: t('updateExistingPayrollFileWithApprovedReimbursements'),
                    value: 'add',
                  },
                ]}
              />
            </FormItem>
          </SectionStepWrapper>

          {currentStep > 1 && (
            <AddPayrollFileStepGroup
              t={t}
              currentStep={currentStep}
              values={formValues}
              onValuesChange={setFormValues}
              loading={modifyPayrollFileMutation.isLoading}
            />
          )}

          <SubmitCancelButtonGroup
            hideCancel={currentStep < 2}
            disabled={modifyPayrollFileMutation.isLoading}
            loading={modifyPayrollFileMutation.isLoading}
            submitDisabled={currentStep !== 5}
            submitText={t('confirmUpdate')}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </Space>
      </Form>
    </section>
  );
};

export default PayrollFileModificationSection;
