import React from 'react';

import SectionStepWrapper from '../../../../components/SectionStepWrapper';
import { selectStoreCurrentCompany, useStoreSelector } from '../../../../utils/storeSelectors';
import SelectApprovedReimbursementsSection from '../../PayrollFileConfigurationSection/SelectApprovedReimbursementsSection';

const ApprovedReimbursementsSection = ({
  t,
  step,
  currentStep,
  loading,
  paymentScheduleId,
  tripReceiptsIds,
  onValuesChange,
}) => {
  const currentCompany = useStoreSelector(selectStoreCurrentCompany);

  return (
    <SectionStepWrapper
      step={step}
      title={t('approvedReimbursements')}
      showContent={currentStep >= step}
      isCompleted={currentStep > step}
    >
      <SelectApprovedReimbursementsSection
        t={t}
        disabled={loading}
        companyId={currentCompany?._id}
        paymentScheduleId={paymentScheduleId}
        selectedRows={tripReceiptsIds}
        onSelect={(selectedKeys, selectedRows) => {
          onValuesChange(state => {
            return {
              ...state,
              tripReceipts: selectedRows,
              tripReceiptsIds: selectedKeys,
            };
          });
        }}
      />
    </SectionStepWrapper>
  );
};

export default ApprovedReimbursementsSection;
