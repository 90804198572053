import { get } from 'lodash';
import React from 'react';

import ApprovedReimbursementsSection from '../FormSteps/ApprovedReimbursementsSection';
import PayrollFileModificationSummary from '../FormSteps/PayrollFileModificationSummary';
import SelectPaymentScheduleSection from '../FormSteps/SelectPaymentScheduleSection';
import SelectPayrollFileSection from '../FormSteps/SelectPayrollFileSection';

const AddPayrollFileStepGroup = ({ t, currentStep, loading, values, onValuesChange }) => {
  if (get(values, 'actionType') !== 'add') return null;

  return (
    <>
      <SelectPaymentScheduleSection t={t} step={2} currentStep={currentStep} loading={loading} />

      <SelectPayrollFileSection
        t={t}
        step={3}
        currentStep={currentStep}
        loading={loading}
        selectedPayrollFile={get(values, 'payrollAuditId')}
        onValuesChange={onValuesChange}
      />

      <ApprovedReimbursementsSection
        t={t}
        step={4}
        currentStep={currentStep}
        loading={loading}
        paymentScheduleId={get(values, 'paymentScheduleId')}
        tripReceiptsIds={get(values, 'tripReceiptsIds', [])}
        onValuesChange={onValuesChange}
      />

      <PayrollFileModificationSummary t={t} step={5} currentStep={currentStep} values={values} />
    </>
  );
};

export default AddPayrollFileStepGroup;
