import { get } from 'lodash';
import React from 'react';
import { useQuery } from 'react-query';

import { fetchCompanyPayrollAudits } from '../../../../api/company';
import SectionStepWrapper from '../../../../components/SectionStepWrapper';
import PayrollFileTable from '../../../../components/Table/PayrollFileTable';
import { selectStoreCurrentCompany, useStoreSelector } from '../../../../utils/storeSelectors';

const SelectPayrollFileSection = ({
  t,
  step,
  currentStep,
  loading,
  selectedPayrollFile,
  onValuesChange,
}) => {
  const currentCompany = useStoreSelector(selectStoreCurrentCompany);

  const payrollFileAuditsQuery = useQuery({
    enabled: Boolean(currentCompany?._id) && currentStep > step - 1,
    queryKey: ['fetchCompanyPayrollAudits', currentCompany?._id],
    queryFn: () => fetchCompanyPayrollAudits(currentCompany?._id),
  });

  return (
    <SectionStepWrapper
      step={step}
      title={t('selectPayrollFile')}
      showContent={currentStep >= step}
      isCompleted={currentStep > step}
    >
      <PayrollFileTable
        t={t}
        scroll={{ x: 400, y: 400 }}
        loading={loading || payrollFileAuditsQuery.isFetching}
        hiddenColumns={['downloadedBy', 'date', 'timeDownloaded']}
        dataSource={get(payrollFileAuditsQuery, 'data', [])}
        pagination={{
          showSizeChanger: false,
        }}
        onRow={record => ({
          onClick: () => {
            onValuesChange(state => {
              return {
                ...state,
                payrollAuditId: get(record, '_id'),
              };
            });
          },
        })}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: [selectedPayrollFile],
          onSelect: record => {
            onValuesChange(state => {
              return {
                ...state,
                payrollAuditId: get(record, '_id'),
              };
            });
          },
        }}
      />
    </SectionStepWrapper>
  );
};

export default SelectPayrollFileSection;
